var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-table"
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "items": _vm.tableItems,
      "no-local-sorting": true
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(name)",
      fn: function fn(rowData) {
        return [_c('user-presentation', {
          staticClass: "table__user-presentation",
          attrs: {
            "name": rowData.item.name,
            "description": rowData.item.description,
            "image": rowData.item.photo,
            "onNameClick": function onNameClick() {
              return _vm.goToProfile(rowData.item);
            }
          }
        })];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "filled round ".concat(_vm.statusClass(rowData.item.status)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(rowData.item.status)) + " ")])], 1)];
      }
    }, {
      key: "cell(productStatuses)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__product-status d-flex  justify-content-center align-items-center"
        }, [_vm._l(rowData.item.productStatuses, function (status, index) {
          return _c('sygni-circle-button', {
            key: index,
            attrs: {
              "status": status
            }
          });
        }), rowData.item.productStatuses.length === 0 ? _c('sygni-rounded-button', {
          staticClass: "outline round disabled",
          attrs: {
            "hoverable": true,
            "plus-icon": true
          }
        }, [_vm._v("Add new product")]) : _vm._e()], 2)];
      }
    }, {
      key: "cell(totalInvestmentValue)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__arrow d-flex justify-content-between align-items-center pl-4 pr-4"
        }, [rowData.item.totalInvestmentValue ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.totalInvestmentValue, 2)) + " PLN ")]) : _c('span', [_vm._v(" 0 PLN")]), rowData.item.productStatuses.length > 0 ? _c('div', {
          staticClass: "arrow"
        }, [_c('sygni-arrow-button', {
          attrs: {
            "open": rowData.detailsShowing,
            "rotate-on-click": true
          }
        })], 1) : _vm._e()])];
      }
    }, {
      key: "cell(sourceName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__source"
        }, [_vm._v(" " + _vm._s(rowData.item.sourceName))])];
      }
    }, {
      key: "cell(created)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.created)) + " ")];
      }
    }, {
      key: "cell(lastLogged)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.lastLogged)) + " ")];
      }
    }, {
      key: "row-details",
      fn: function fn(rowData) {
        return [_c('investors-inner-table', {
          attrs: {
            "items": rowData.item.details
          }
        }), _c('div', {
          staticClass: "add-product-container"
        }, [_c('sygni-rounded-button', {
          staticClass: "outline disabled round",
          attrs: {
            "hoverable": true,
            "plus-icon": true
          },
          on: {
            "click": function click($event) {
              return _vm.openAddNewProductForm(rowData.item.id);
            }
          }
        }, [_vm._v(" Add new product ")])], 1)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }