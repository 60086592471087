var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-module"
  }, [_c('investors-blind-filters'), _c('div', {
    staticClass: "table-container"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-10"
  }, [_c('sygni-container-title', {
    staticClass: "header"
  }, [_vm._v("Investors list")])], 1), _c('div', {
    staticClass: "col-2 add-new-investor-button"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-primary",
    attrs: {
      "selectable": false,
      "plus-icon": true
    }
  }, [_vm._v("Add new investor")])], 1)])]), _c('div', {
    staticClass: "table-container__table"
  }, [_c('investors-blind-table')], 1)]), _c('sygni-access-modal')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }